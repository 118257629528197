<div class="shop-header">
  <div class="shop-header-title" (click)="navigateBackToDashboard()" (keypress)="navigateBackToDashboard()" aria-hidden="true">
    <img
      tabindex="0"
      class="shop-header-back-icon"
      alt="back icon"
      src="/assets/shop/vectors/chevron-left.svg"
    />
    <wlx-heading>
      <wlx-heading-lead x1>
        Shop
      </wlx-heading-lead>
    </wlx-heading>
  </div>
  <div class="shop-header-navigation">
    <div [routerLink]="['/', 'order']" class="shop-header-navigation-item orders">Meine Bestellungen</div>
    <div class="shop-header-navigation-item separator"></div>
    <div [routerLink]="['/', 'basket']" class="shop-header-navigation-item cart">
      {{ kioskService.getBasketItemCount() }} Artikel &nbsp; &nbsp;
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.55 11C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C20.25 2.82 19.77 2 19.01 2H4.21L3.27 0H0V2H2L5.6 9.59L4.25 12.03C3.52 13.37 4.48 15 6 15H18V13H6L7.1 11H14.55ZM5.16 4H17.31L14.55 9H7.53L5.16 4ZM6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
          fill="#323232"
        />
      </svg>
    </div>
  </div>
</div>
<br />
<br />
<div class="shop-category-grid">
  <div
    *ngFor="let category of kioskService.categories"
    [routerLink]="['/', 'shop', category.id]"
    class="shop-category-grid-item"
    [style.background-image]="'url(/assets/shop/categories/' + category.id + '.png)'"
  >
    <div class="shop-category-item-data">
      <span class="shop-category-item-category">
        {{ $any(category).type }}
      </span>
      <span class="shop-category-item-name">
        {{ category.name }}
      </span>
    </div>
  </div>
  <div routerLink="/shop/upgrades" class="shop-category-grid-item span-2 upgrades">
    <div class="upgrades-text">
      <span class="upgrades-subtitle">Upgrades & Extras</span>
      <span class="upgrades-title">Noch mehr Entspannung</span>
    </div>
  </div>
</div>
